import { useRequest } from 'ahooks';
import { Cascader, DatePicker, Form, InputNumber, Modal, Select, Space, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { PropertyType } from '../../../api/type';
import {
  apiDataPropertyListByDeviceTypeRulesIdGet,
  DataPropertyListByDeviceTypeRulesIdGetResponse,
} from '../../../ytt/types/dataProperty/listByDeviceTypeRulesId';
import { RulesSetIdGetResponse } from '../../../ytt/types/rulesSet/id';
import styles from './index.module.scss';
import { DefaultOptionType } from 'antd/es/cascader';
import { apiDeviceGet } from '../../../ytt/types/api/device';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { apiRulesSetAddAppendDataPost } from '@maxtropy/device-mock-apis';
import { useAsync } from '@maxtropy/components';
import { getDeviceTypeData } from '@/api/attribute';
import { getDeviceList } from '@/api/mockDevice';

interface Iprops {
  detail: RulesSetIdGetResponse;
  deviceId?: number;
  onCancel?: () => void;
  onConfirm?: () => void;
}

interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

interface CascadingMultipleData {
  label: string;
  value: number;
  child: Array<CascadingMultipleData> | undefined;
}

interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

const { RangePicker } = DatePicker;

const AddDataSupplementModal: FC<Iprops> = props => {
  const { detail, deviceId, onCancel, onConfirm } = props;
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = useForm();

  const [propertyList, setPropertyList] = useState<DataPropertyListByDeviceTypeRulesIdGetResponse>([]);
  const [deviceTypeId, setDeviceTypeId] = useState<string>();
  const deviceTypeData = useAsync(getDeviceTypeData);

  useEffect(() => {
    if (!deviceTypeId) return;
    apiDataPropertyListByDeviceTypeRulesIdGet({ deviceTypeId, rulesId: String(detail.dataRuleId) }).then(res => {
      setPropertyList(res ?? []);
      form.setFieldsValue({
        dataPropertyList: res?.map(i => i.id),
      });
    });
  }, [deviceTypeId]);

  const onDataSupplement = () => {
    form.validateFields().then(values => {
      apiRulesSetAddAppendDataPost({
        ...values,
        deviceTypeId,
        rulesSetId: detail.id,
        beginTime: values.beginEndTime ? values.beginEndTime[0] : undefined,
        endTime: values.beginEndTime ? values.beginEndTime[1] : undefined,
      }).then(() => {
        onConfirm?.();
      });
    });
  };

  const onDeviceTypeSelect = (val: (string | number | null)[]) => {
    form.setFieldsValue({ deviceList: [] });
    setSearchStr('');
    const currentTypeId = val[val.length - 1];
    setDeviceTypeId(String(currentTypeId));
  };

  const formatOptionData = (response: DeviceTypeTree | undefined): Array<CascadingMultipleData> | undefined => {
    const loop = (data: Array<DeviceTypes>): Array<CascadingMultipleData> => {
      return data.map(item => ({
        label: item.name,
        value: item.id,
        child: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : undefined,
      }));
    };
    if (!response) return undefined;
    return loop(response.tree);
  };

  const [searchStr, setSearchStr] = useState<string>();
  const handleSearch = (newValue: string) => {
    setSearchStr(newValue);
  };
  const { data: searchData, loading: searchLoading } = useRequest(
    () => {
      return getDeviceList({ deviceTypeId: deviceTypeId, tenantMcid: detail?.mcid!, name: searchStr }).then(res => {
        return (res ?? []).splice(0, 20);
      });
    },
    {
      debounceWait: 300,
      refreshDeps: [searchStr, deviceTypeId],
      ready: !!deviceTypeId && !!detail?.mcid,
    }
  );

  const tempDeviceList = useMemo(() => {
    // 编辑
    if (deviceTypeData && deviceId) {
      const currentDevice = detail?.deviceList?.find(i => i.id === deviceId);
      let currentList = currentDevice?.deviceList ?? [];
      let hasExist = currentList.map(item => item.id);
      if (searchData && searchData.length > 0) {
        let temp = searchData.filter(item => !hasExist.includes(item.id));
        currentList = [...currentList, ...temp] as any;
      } else {
        currentList = [];
      }
      return currentList;
    } else {
      return searchData ?? [];
    }
  }, [searchData, deviceTypeData, deviceId]);

  const disabledDate = (current: Dayjs) => {
    return (current && current >= dayjs().endOf('day')) || (current && current <= dayjs('2013-12-31').endOf('day'));
  };

  return (
    <>
      <Modal
        title="数据补录"
        open
        width={600}
        closable={false}
        onCancel={() => {
          modalApi.confirm({
            title: '您是否确认放弃录入的信息？',
            onOk() {
              onCancel?.();
            },
            onCancel() {},
          });
        }}
        onOk={() => {
          onDataSupplement();
        }}
      >
        <div className={styles.modal_box}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              unifiedCompute: detail.unifiedCompute,
              yMagnificationValue: detail.yMagnificationValue,
              cumulativeStartValue: detail.cumulativeStartValue,
            }}
          >
            <Form.Item label="选择类目" name="deviceTypeId" rules={[{ required: true }]}>
              <Cascader
                options={formatOptionData(deviceTypeData as DeviceTypeTree)}
                allowClear={false}
                fieldNames={{ children: 'child' }}
                placeholder={'请选择所属类目'}
                onChange={onDeviceTypeSelect}
              />
            </Form.Item>
            <Form.Item label="选择设备" name="deviceList" rules={[{ required: true }]}>
              <Select
                mode="multiple"
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                placeholder="请输入关键字搜索"
                onSearch={handleSearch}
                filterOption={false}
                loading={searchLoading}
                // notFoundContent={searchLoading ? <Spin size="small" /> : <div>暂无数据</div>}
                options={tempDeviceList.map(i => ({ label: i.name, value: i.id }))}
              ></Select>
            </Form.Item>
            <Form.Item label="选择数据属性" name="dataPropertyList" rules={[{ required: true }]}>
              <Select mode="multiple" options={propertyList.map(i => ({ label: i.name, value: i.id }))}></Select>
            </Form.Item>
            {/* 仅累积量显示, 瞬时量不显示 */}
            {detail.propertyType === PropertyType.ACCUMULATE && (
              <Form.Item label={detail.cumulativeStartName} name="cumulativeStartValue" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
            )}

            <Form.Item label={detail.yMagnificationName} name="yMagnificationValue" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            <Form.Item valuePropName="checked" label="统一计算" name="unifiedCompute">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  补录数据时段
                  <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />
                  <span>补录时段为近3天的数据会走优先队列</span>
                </Space>
              }
              name="beginEndTime"
            >
              <RangePicker style={{ width: '100%' }} showTime format="YYYY-MM-DD HH:mm" disabledDate={disabledDate} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default AddDataSupplementModal;
