import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Input, Modal, Radio, Select } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import { DataPointType, DataProperty, DataPropertyType } from '../../../../types';
import { EdgeDeviceTemplatePoint } from './index';
import { useQuery } from '../../../../utils/utils';
import { getPhysicalUnits } from '../../../../api/options';
import FormulaInput, { formulaValidator } from '../../../FormulaInput';
import NumberRange from '../../../EdgeDevicePointInfo/mockingbird/Virtual/NumberRange';
import { PointContext } from '../../../EdgeDevicePointInfo/contextTypes';
import FormSubmit from '../../../EdgeDevicePointInfo/mockingbird/FormSubmit';
import { identifierValidatorBuilder } from '../../../EdgeDevicePointInfo/mockingbird/Point/entity';
import ShowInput from '../../../ShowInput';
import { BatchEdgeContent } from '../../contentTypes';
import { PointOperateType } from '../../interface';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { InputNumber } from '@maxtropy/components';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};

interface FormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties: number[];
  usedIdentifier: string[];
  dataProperties: DataProperty[];
  alterMode?: boolean;
}

const FormDialog: React.FC<FormDialogProps> = props => {
  const {
    row,
    onCancel,
    onOk,
    usedProperties,
    usedIdentifier,
    dataProperties: dataPropertiesAll,
    alterMode = false,
  } = props;
  const [form] = Form.useForm();
  const [dataPropertyId, setDataPropertyId] = useState<number>();
  const { setRow } = useContext(PointContext);
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType } = baseForm?.getFieldsValue();

  const valueFilterMode = Form.useWatch('valueFilterMode', form);

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties.includes(item.id)),
    [dataPropertiesAll, usedProperties]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    if (shouldContinue) {
      onOk(values);
      form.resetFields();
      setRow?.({ pointType: DataPointType.VIRTUAL_POINT });
    } else {
      onOk(values);
      onCancel();
    }
  };

  return (
    <Modal
      width={600}
      open={row?.pointType === DataPointType.VIRTUAL_POINT}
      onCancel={onCancel}
      title={`${pointOperateType === PointOperateType.UPDATE ? '编辑' : '添加'}虚拟点`}
      centered={true}
      maskClosable={false}
      okText="确定"
      cancelText="取消"
      footer={<FormSubmit submit={submit} cancel={onCancel} alterMode={alterMode} />}
    >
      <Form form={form} initialValues={row} {...formLayout}>
        <Form.Item noStyle name="pointType" />
        {alterMode ? <Form.Item noStyle name="dataPropertyId" /> : <Form.Item noStyle name="dataPropertyName" />}
        <Form.Item noStyle name="physicalUnitGeneralName" />
        {alterMode ? (
          <Form.Item noStyle name="hasProperty" />
        ) : (
          <Form.Item
            name="hasProperty"
            label="虚拟点类型"
            rules={[{ required: true, message: '请选择虚拟点类型' }]}
            initialValue={true}
          >
            <Radio.Group
              disabled={!isNil(row?.id)}
              onChange={() => {
                form.setFieldsValue({
                  dataPropertyId: undefined,
                  dataPropertyName: '',
                  physicalUnitId: undefined,
                  physicalUnitGeneralName: '',
                  identifier: '',
                });
              }}
            >
              <Radio value={true}>建模虚拟点</Radio>
              <Radio value={false}>非建模虚拟点</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {alterMode ? (
          <>
            <Form.Item
              name={row?.hasProperty ? 'dataPropertyName' : 'identifier'}
              label={row?.hasProperty ? '数据属性' : '非建模点标识符'}
            >
              <ShowInput />
            </Form.Item>
            {dataPropertyType === DataPropertyType.YC && (
              <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                <Select
                  placeholder="请输入/选择"
                  showSearch
                  onSearch={value => units?.filter(item => item.generalName.includes(value))}
                  onChange={value => {
                    form.setFieldsValue({
                      physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                    });
                  }}
                >
                  {units?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.generalName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}>
            {({ getFieldsValue }) => {
              const { hasProperty } = getFieldsValue();
              return hasProperty ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        form.setFieldsValue({
                          physicalUnitId: undefined,
                          physicalUnitGeneralName: '',
                        });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataProperties?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        placeholder="请输入/选择"
                        showSearch
                        onSearch={value => units?.filter(item => item.generalName.includes(value))}
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  name="identifier"
                  label="非建模虚拟点标识符"
                  validateFirst
                  required
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        )}

        <Form.Item
          name="writable"
          label="是否可写"
          rules={[{ required: true, message: '请选择是否可写' }]}
          initialValue={false}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="formula"
          label="公式"
          validateFirst
          rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
        >
          <FormulaInput
            identifier={usedIdentifier.map(value => ({
              id: `x000${value}()`,
              display: value,
              description: '非建模',
            }))}
            dataProperties={dataPropertiesAll.map(item => ({
              id: `x_${item.id}`,
              display: item.name,
              description: '建模',
            }))}
          />
        </Form.Item>
        <Form.Item
          name="valueFilterMode"
          label="数据过滤模式"
          initialValue={FilterMode.NUMBERREASONABLERANGE}
          shouldUpdate
        >
          <Radio.Group>
            {Object.entries(FilterModeDisplay).map(([k, v]) => {
              if (Number(k) !== FilterMode.NOCONFIG) {
                return (
                  <Radio key={k} value={Number(k)}>
                    {v}
                  </Radio>
                );
              }
              return null;
            })}
          </Radio.Group>
        </Form.Item>
        {valueFilterMode === 1 && (
          <Form.Item name="valueRange" label="数值合理区间">
            <NumberRange />
          </Form.Item>
        )}
        {valueFilterMode === 2 && (
          <>
            <Form.Item label="增长值合理区间" name="cloudValueRange">
              <NumberRange showUnit />
            </Form.Item>
            <Form.Item name="cloudValueFilterCnt" label="云端异常数据过滤个数" initialValue={5}>
              <InputNumber
                placeholder="请输入"
                min={1}
                max={1000}
                precision={0}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </>
        )}
        {!alterMode && (
          <Form.Item name="remark" label="备注">
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default FormDialog;
