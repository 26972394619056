import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormContent, Wrapper } from '@maxtropy/components';
import { Form, Radio, Input, Checkbox, Row, Col, DatePicker, Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import TextArea from 'antd/es/input/TextArea';
import type { RadioChangeEvent } from 'antd';
import dayjs from 'dayjs';
import { compact } from 'lodash';
import { apiCarbonIndustryAllPost } from '@maxtropy/device-mgmt-apis';
import Fuel from './components/Fuel';
import Carbonate from './components/Carbonate';
import Electric from './components/Electric';
import Heat from './components/Heat';
import Steam from './components/Steam';
import Mcf from './components/Mcf';
import Gwp from './components/Gwp';
import IndustrialProcess from './components/IndustrialProcess';
import {
  apiV2CarbonEmissionLibraryAddOrUpdatePost,
  apiV2CarbonEmissionLibraryDetailPost,
} from '@maxtropy/tody-mgmt-apis-v2';
import { CarbonIndustryData, TypeEnum, typeValue } from '../EmissionFactor/type';
import styles from './index.module.scss';
import { FactorPropertyEnum } from './emissionProperty';

const routes = [{ name: '双碳管理' }, { name: '基本信息' }, { name: '排放因子库' }, { name: '新建排放因子' }];

const stringTypes = [
  FactorPropertyEnum.FuelUnit,
  FactorPropertyEnum.CarbonateUnit,
  FactorPropertyEnum.CarbonateMolecularFormula,
  FactorPropertyEnum.ElectricUnit,
  FactorPropertyEnum.HeatUnit,
  FactorPropertyEnum.MCFApproach,
  FactorPropertyEnum.MCFRange,
  FactorPropertyEnum.MCFRemark,
  FactorPropertyEnum.ProcessMethod,
  FactorPropertyEnum.GWPValue,
  FactorPropertyEnum.CommonUnit,
];
interface Props {}

const EmissionForm: FC<Props> = () => {
  const [industryData, setIndustryData] = useState<CarbonIndustryData[]>([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const fuelType = Form.useWatch('type', form) as TypeEnum;

  const checkAll = industryData.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < industryData.length;

  useEffect(() => {
    apiCarbonIndustryAllPost().then(res => {
      if (res) {
        setIndustryData(res as CarbonIndustryData[]);
      }
    });
  }, []);

  const formatFactorDetail = (type: TypeEnum, list: any[]) => {
    if ([TypeEnum.MCF, TypeEnum.INDUSTRIAL_PROCESS].includes(type)) {
      // MCF和工艺生产过程是动态增减，需分开处理
      let newList = list.reduce((acc: any, cur: any) => {
        const { factorOrder, factorPropertyId, defaultValue } = cur;

        const value = stringTypes.includes(factorPropertyId) ? defaultValue : Number(defaultValue);

        acc[factorOrder] = {
          ...acc[factorOrder],
          [`${factorPropertyId}`]: value,
        };

        return acc;
      }, {});

      return Object.values(newList);
    } else {
      const newData: Record<string, any> = {};

      list.forEach(({ factorPropertyId, factorOrder, defaultValue }) => {
        const value = stringTypes.includes(factorPropertyId) ? defaultValue : Number(defaultValue);

        newData[`${factorPropertyId}_${factorOrder}`] = value;
      });

      return newData;
    }
  };

  useEffect(() => {
    if (id) {
      apiV2CarbonEmissionLibraryDetailPost({ id: Number(id) }).then(res => {
        if (res) {
          const { factorDetails, type, industryIds, calculatingTime, ...rest } = res;
          const params: Record<string, any> = {
            ...rest,
            type,
            industryIds,
          };

          if (factorDetails) {
            params.factorDetails = formatFactorDetail(type as TypeEnum, factorDetails);
          }
          if (calculatingTime) {
            params.calculatingTime = dayjs(calculatingTime);
          }

          form.setFieldsValue(params);

          setCheckedList(industryIds || []);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const plainOptions = industryData.map(item => item.id);
    setCheckedList(e.target.checked ? plainOptions : []);
    form.setFieldValue('industryIds', e.target.checked ? plainOptions : []);
  };

  const onChange = (list: any[]) => {
    setCheckedList(list as number[]);
  };

  const onChangeType = (e: RadioChangeEvent) => {
    form.setFieldValue('factorDetails', undefined);
  };
  const splitValue = (name: string) => {
    const values = name.split('_');
    return {
      factorPropertyId: values[0],
      factorOrder: values[1],
    };
  };

  const onSave = (bol: boolean) => {
    form.validateFields().then(formData => {
      const params = { ...formData };

      params.industryIds = checkedList;

      if (formData.calculatingTime) {
        params.calculatingTime = new Date(formData.calculatingTime).toISOString();
      }

      // 处理动态增减的数据
      params.factorDetails = [];
      if (Array.isArray(formData.factorDetails)) {
        compact(formData.factorDetails).forEach((fd: any, index) => {
          Object.keys(fd).forEach((key: any) => {
            if (fd[key] !== undefined) {
              params.factorDetails.push({
                defaultValue: fd[key],
                ...splitValue(key),
                factorOrder: index + 1,
              });
            }
          });
        });
      } else {
        Object.keys(formData.factorDetails).forEach((item: any) => {
          if (formData.factorDetails[item] !== undefined) {
            params.factorDetails.push({
              defaultValue: formData.factorDetails[item],
              ...splitValue(item),
            });
          }
        });
      }

      // enable 1：启用 其他都是草稿状态
      params.enable = bol ? 1 : 0;
      if (id) {
        params.id = Number(id);
      }
      apiV2CarbonEmissionLibraryAddOrUpdatePost(params).then(() => {
        navigate(-1);
      });
    });
  };

  return (
    <Wrapper routes={routes} className={styles.content}>
      <Form layout="vertical" form={form}>
        <FormContent title="基础信息">
          <Form.Item label="类型" name="type" initialValue={1} rules={[{ required: true }]}>
            <Radio.Group
              disabled={!!id}
              options={Object.entries(typeValue).map(([value, label]) => ({ label, value: Number(value) }))}
              onChange={onChangeType}
            />
          </Form.Item>
          <Form.Item
            label="名称"
            name="name"
            rules={[
              { required: true },
              { max: 50, message: '名称长度不能大于50个字符' },
              { min: 2, message: '名称长度不能少于2个字符' },
            ]}
          >
            <Input style={{ width: 400 }} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="测算时间" name="calculatingTime">
            <DatePicker picker="year" style={{ width: 400 }} />
          </Form.Item>
          <Form.Item label="适用地区" name="applyDistrict" rules={[{ max: 50, message: '名称长度不能大于50个字符' }]}>
            <Input style={{ width: 400 }} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="适用行业" required>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              全选
            </Checkbox>
          </Form.Item>
          <div className={styles.divider} />
          <Form.Item name="industryIds" rules={[{ required: true, message: '请选择适用行业' }]}>
            <Checkbox.Group style={{ width: '100%', display: 'inline-block' }} onChange={onChange}>
              <Row>
                {industryData?.map((item: any) => {
                  return (
                    <Col span={6}>
                      <Checkbox value={item.id}>{item.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </FormContent>
        <FormContent
          title="因子数据"
          extraContent={<div className={styles.subtitle}>已选择基本类型【{typeValue[fuelType]}】</div>}
        >
          <div>
            {fuelType === TypeEnum.FUEL && <Fuel />}
            {fuelType === TypeEnum.CARBONATE && <Carbonate />}
            {fuelType === TypeEnum.ELECTRIC && <Electric />}
            {fuelType === TypeEnum.HEATING && <Heat />}
            {fuelType === TypeEnum.STEAM && <Steam />}
            {fuelType === TypeEnum.MCF && <Mcf />}
            {fuelType === TypeEnum.INDUSTRIAL_PROCESS && <IndustrialProcess />}
            {fuelType === TypeEnum.GWP && <Gwp />}
            {fuelType === TypeEnum.TRANSPORTATION && <Electric type={TypeEnum.TRANSPORTATION} />}
            {fuelType === TypeEnum.GAS_DISPERSION && <Electric type={TypeEnum.GAS_DISPERSION} />}
            {fuelType === TypeEnum.WASTE_DISPOSAL && <Electric type={TypeEnum.WASTE_DISPOSAL} />}
            {fuelType === TypeEnum.EMPLOYEE_COMMUTING && <Electric type={TypeEnum.EMPLOYEE_COMMUTING} />}
          </div>
          <Form.Item label="可见性" name="tenantVisibleSigns" initialValue={0} rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>租户端不可见</Radio>
              <Radio value={1}>租户端可见</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="来源" name="reference" rules={[{ max: 200, message: '名称长度不能大于200个字符' }]}>
            <TextArea placeholder="请输入" autoSize={{ minRows: 3 }} />
          </Form.Item>
          <Form.Item label="说明" name="description" rules={[{ max: 200, message: '名称长度不能大于200个字符' }]}>
            <TextArea placeholder="请输入" autoSize={{ minRows: 3 }} />
          </Form.Item>
        </FormContent>
        <Space style={{ paddingLeft: '40px' }} size={8}>
          <Button type="primary" onClick={() => onSave(true)}>
            保存并启用
          </Button>
          <Button type="default" onClick={() => onSave(false)}>
            保存
          </Button>
          <Button type="default" onClick={() => navigate(-1)}>
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default EmissionForm;
