import React, { Key, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Button, Checkbox, Col, Divider, Empty, Layout, Radio, Row, Select, Space, Spin } from 'antd';
import { ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Breadcrumb, EllipsisSpan, Modal, Paging, Table, useAsync, usePaging } from '@maxtropy/components';
import { getRoot } from '../../api/device';
import {
  DevicePoint,
  exportHistoryData,
  FrequencyType,
  getDeviceCompany,
  getDevicePoints,
  getHistoryData,
  getRawChartData,
  getRawPageData,
  getRawStrPageData,
  MeasurementType,
  RawData,
  RawPageDataResponse,
} from '../../api/history';
import HistoryTab from './components/HistoryTab';
import DeviceTree from './components/DeviceTree';
import HistoryChart, { HistoryOriginalChart } from './components/HistoryChart';
import DateSwitch, {
  DatePickerType,
  getColumnConfig,
  getTimeDistance,
  getTsRange,
  OriginalType,
  RangePickerValue,
  StatisticsPartition,
} from './components/DateSwitch';
import styles from './index.module.scss';
import { DataPropertyType } from '@/shared/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  apiV2DataPropertyNonModelListByDeviceIdPost,
  apiV2HistoryDataNonModelListPost,
  apiV2HistoryDataNonModelPagePost,
} from '@maxtropy/tody-mgmt-apis-v2';

const CheckboxGroup = Checkbox.Group;

const { Option } = Select;
const { Sider, Content } = Layout;

const routes = [{ name: '数据中心' }, { name: '历史数据' }, { name: '设备历史数据' }];

export enum PageModeType {
  CHART = 'chart',
  TABLE = 'table',
}

export const partitionToQuery = {
  [DatePickerType.DAY]: FrequencyType.DAY,
  [DatePickerType.MONTH]: FrequencyType.MONTH,
  [DatePickerType.YEAR]: FrequencyType.YEAR,
  [OriginalType.ORIGINAL]: FrequencyType.ORIGINAL,
  [OriginalType.ORIGINALENUM]: FrequencyType.ORIGINAL,
};

function getDevicePointDisplay(point: DevicePoint, value: Key, mode: StatisticsPartition) {
  if (value === null || value === undefined) {
    return value;
  }
  switch (point.type) {
    case DataPropertyType.YC:
      if (mode === OriginalType.ORIGINAL) {
        // 原始数据，无论是图表还是列表状态，都不限制数据的小数位数
        return value as number;
      }
      return (value as number).toFixed(2);
    case DataPropertyType.ENUM:
      return point.enumValue![value as number];
    case DataPropertyType.STRING:
      return value;
    default:
      return value;
  }
}

interface EffectValue {
  activePoint?: DevicePoint;
  dateMode?: StatisticsPartition;
  rangePickerValue?: RangePickerValue;
  pageMode?: PageModeType;
}

enum PointTypeEnum {
  MODEL_POINT = 1,
  NONE_MODEL_POINT = 2,
}

const History: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [pageMode, setPageMode] = useState<PageModeType>(PageModeType.CHART);
  const [tenantMcid, setTenantMcid] = useState<string>(
    urlSearchParams.has('tenantMcid') ? (urlSearchParams.get('tenantMcid') as string) : ''
  );
  const [companyId, setCompanyId] = useState<number>();
  const [deviceId, setDeviceId] = useState<number | undefined>(
    urlSearchParams.has('deviceId') ? (Number(urlSearchParams.get('deviceId')) as number) : undefined
  );
  const [dateMode, setDateMode] = useState<StatisticsPartition>(OriginalType.ORIGINAL);
  const [rangePickerValue, setRangePickerValue] = useState<RangePickerValue>(
    getTimeDistance(OriginalType.ORIGINALENUM)
  );
  const [activePoint, setActivePoint] = useState<DevicePoint>();
  const [pointType, setPointType] = useState<PointTypeEnum>(PointTypeEnum.MODEL_POINT);

  const [activePointIndex, setActivePointIndex] = useState<number>(0);

  const ref = useRef<DevicePoint>();

  // fix: 将多个依赖收集到一起，通过xx变化触发Effect
  const effectChange = useCallback((value: EffectValue) => {
    if (value.activePoint !== undefined) {
      setActivePoint(value.activePoint);
      ref.current = value.activePoint;
    }
    if (value.dateMode !== undefined) {
      setDateMode(value.dateMode);
    }
    if (value.rangePickerValue !== undefined) {
      setRangePickerValue(value.rangePickerValue);
    }
    if (value.pageMode !== undefined) {
      setPageMode(value.pageMode);
    }
    setPageOffset(1);
    forceUpdate();
  }, []);

  const onPointChange = (point?: DevicePoint) => {
    setTableData([]);
    setHistoryData([]);
    setChartData([]);
    if (point) {
      // 建模点
      if (pointType === PointTypeEnum.MODEL_POINT) {
        setPageOffset(1);
        const effectValue: EffectValue = {
          activePoint: point,
        };
        // 判断是否为枚举类型
        if (point.type === DataPropertyType.ENUM || point.type === DataPropertyType.STRING) {
          if (dateMode !== OriginalType.ORIGINALENUM) {
            effectValue.pageMode = PageModeType.TABLE;
            effectValue.dateMode = OriginalType.ORIGINALENUM;
            effectValue.rangePickerValue = getTimeDistance(OriginalType.ORIGINALENUM);
          }
        } else {
          if (dateMode === OriginalType.ORIGINALENUM) {
            effectValue.dateMode = OriginalType.ORIGINAL;
            effectValue.rangePickerValue = getTimeDistance(OriginalType.ORIGINAL);
          }
        }
        effectChange(effectValue);
      }
      // 非建模点
      else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
        setPageOffset(1);
        const effectValue: EffectValue = {
          activePoint: point,
          dateMode: OriginalType.ORIGINAL,
        };
        effectChange(effectValue);
      }
    }
  };

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const fetchTenantRequest = useCallback(async () => {
    const response = await getRoot();
    if (response) {
      return response;
    }
    return undefined;
  }, []);

  const tenant = useAsync(fetchTenantRequest);

  const fetchCompanyRequest = useCallback(async () => {
    const response = await getDeviceCompany();
    if (response) {
      setCompanyId(response[0].id);
      return response;
    }
    return undefined;
  }, []);

  const deviceCompany = useAsync(fetchCompanyRequest);

  const [devicePoints, setDevicePoints] = useState<DevicePoint[]>([]);

  useEffect(() => {
    if (Array.isArray(tenant) && tenant.length) {
      if (!tenantMcid) {
        setTenantMcid(tenant[0].mcid);
      }
    }
  }, [tenant, tenantMcid]);

  useEffect(() => {
    if (deviceId) {
      // 建模点属性tab列表
      if (pointType === PointTypeEnum.MODEL_POINT) {
        getDevicePoints(deviceId).then(response => {
          if (response) {
            let index = response.length > activePointIndex ? activePointIndex : response.length - 1;
            if (activePointIndex === -1 && response.length) {
              index = 0;
            }
            setActivePointIndex(index);
            onPointChange(response[index]);
            setDevicePoints(response);
          }
        });
      }
      // 非建模点属性列表
      else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
        apiV2DataPropertyNonModelListByDeviceIdPost({ id: deviceId }).then(response => {
          const list = (response.list ?? [])
            .map(item => {
              return {
                id: item.id! as any,
                name: item.identifier!,
                measurementType: MeasurementType.INSTANT,
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
          let index = list.length > activePointIndex ? activePointIndex : list.length - 1;
          if (activePointIndex === -1 && list) {
            index = 0;
          }
          setActivePointIndex(index);
          onPointChange(list[index]);
          setDevicePoints(list);
        });
      }
    } else {
      setActivePoint(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, pointType]);

  // 按日按月按年的历史数据
  const [historyData, setHistoryData] = useState<RawData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setHistoryLatestData = (id: number, data: RawData[]) => {
    if (ref.current && ref.current.id === id) {
      setHistoryData(data);
    }
  };

  useEffect(() => {
    if (
      rangePickerValue &&
      deviceId &&
      activePoint &&
      activePoint.type === DataPropertyType.YC &&
      dateMode !== OriginalType.ORIGINAL &&
      dateMode !== OriginalType.ORIGINALENUM
    ) {
      setIsLoading(true);
      const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
      const activePointId = activePoint.id;
      getHistoryData({
        deviceId,
        propertyId: activePointId,
        startTime: tsStart,
        endTime: tsEnd,
        frequency: partitionToQuery[dateMode],
        measurementType: activePoint.measurementType,
      }).then(response => {
        setHistoryLatestData(activePointId, response);
        setIsLoading(false);
      });
    } else {
      setHistoryData([]);
    }
  }, [xx]);

  // 原始数据表格
  const [tableData, setTableData] = useState<RawData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const setTableLatestData = (id: number, response: RawPageDataResponse) => {
    if (ref.current && ref.current.id === id) {
      if (response) setTotalCount(response.total);
      setTableData(response.list);
    }
  };

  useEffect(() => {
    // 建模点
    if (pointType === PointTypeEnum.MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.TABLE &&
        (dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM)
      ) {
        setTableLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        const query = {
          page: pageOffset,
          size: pageSize,
          deviceId,
          propertyId: activePoint.id,
          startTime: tsStart,
          endTime: tsEnd,
          type: activePoint.type!,
        };
        if (activePoint.type === DataPropertyType.STRING) {
          getRawStrPageData(query).then(page => {
            setTableLatestData(activePointId, page);
            setTableLoading(false);
          });
        } else {
          getRawPageData(query).then(page => {
            setTableLatestData(activePointId, page);
            setTableLoading(false);
          });
        }
      } else {
        setTableData([]);
      }
    }
    // 非建模点
    else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.TABLE &&
        (dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM)
      ) {
        setTableLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        apiV2HistoryDataNonModelPagePost({
          id: String(activePointId),
          from: tsStart,
          to: tsEnd,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          setTableLatestData(activePointId, res as RawPageDataResponse);
          setTableLoading(false);
        });
      }
    }
  }, [pageOffset, pageSize, setTotalCount, xx]);

  // 原始数据图表
  const [chartData, setChartData] = useState<RawData[]>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  const setChartLatestData = (id: number, data: RawData[]) => {
    if (ref.current && ref.current.id === id) {
      setChartData(data);
    }
  };

  useEffect(() => {
    // 建模点
    if (pointType === PointTypeEnum.MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.CHART &&
        dateMode === OriginalType.ORIGINAL
      ) {
        setChartLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        getRawChartData({
          deviceId,
          propertyId: activePointId,
          startTime: tsStart,
          endTime: tsEnd,
        }).then(response => {
          setChartLatestData(activePointId, response);
          setChartLoading(false);
        });
      } else {
        setChartData([]);
      }
    } else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.CHART &&
        dateMode === OriginalType.ORIGINAL
      ) {
        setChartLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        apiV2HistoryDataNonModelListPost({
          id: String(activePointId),
          from: tsStart,
          to: tsEnd,
        }).then(res => {
          setChartLatestData(activePointId, res.list as RawData[]);
          setChartLoading(false);
        });
      }
    }
  }, [xx]);

  const handleRangePickerChange = (value: RangePickerValue) => {
    effectChange({
      rangePickerValue: value,
    });
  };

  const columnConfig = getColumnConfig(dateMode);

  const columns = [
    {
      title: columnConfig.name,
      dataIndex: 'time',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v ? dayjs(v).format(columnConfig.dateFormat) : null} />,
    },
    {
      title: activePoint?.name,
      dataIndex: 'value',
      ellipsis: { showTitle: true },
      render: (v: number) => (
        <EllipsisSpan value={isNil(v) ? v : `${getDevicePointDisplay(activePoint!, v, dateMode)}`} />
      ),
    },
  ];

  const renderTableContent = () => {
    const isOriginal = dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM;
    return (
      <>
        <div className={styles.unit}>单位：{activePoint?.generalName ?? '-'}</div>
        <Table
          loading={isOriginal ? tableLoading : isLoading}
          dataSource={isOriginal ? tableData : historyData}
          columns={columns}
          rowKey="time"
        />
        {isOriginal && <Paging pagingInfo={pagingInfo} />}
      </>
    );
  };

  const renderChart = () => {
    if (dateMode === OriginalType.ORIGINALENUM) {
      return null;
    }
    const isOriginal = dateMode === OriginalType.ORIGINAL;
    if (isOriginal) {
      return (
        <HistoryOriginalChart key={activePoint?.id} loading={chartLoading} data={chartData} activePoint={activePoint} />
      );
    } else {
      return (
        <HistoryChart
          key={activePoint?.id}
          loading={isLoading}
          data={historyData}
          activePoint={activePoint}
          mode={dateMode as DatePickerType}
        />
      );
    }
  };
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const [checkedList, setCheckedList] = useState<any[]>([]);

  const onChange = (list: any[]) => {
    setCheckedList(list);
  };

  const exportBtn = () => {
    if (deviceId && checkedList.length > 0 && rangePickerValue) {
      const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
      exportHistoryData({
        deviceId,
        timeSolution: partitionToQuery[dateMode],
        propertyId: checkedList as number[],
        startTime: tsStart,
        endTime: tsEnd,
        type: String(pointType),
      });
    }
  };
  // 非原始数据, 枚举值disabled
  const checkDataList = useMemo(() => {
    if (pointType === PointTypeEnum.MODEL_POINT) {
      return devicePoints.map(item => {
        return {
          ...item,
          disabled:
            ![OriginalType.ORIGINALENUM, OriginalType.ORIGINAL].includes(dateMode as any) &&
            item.type === DataPropertyType.ENUM,
        };
      });
    } else {
      return devicePoints.map(item => {
        return {
          ...item,
          disabled: false,
        };
      });
    }
  }, [devicePoints, dateMode, pointType]);

  useEffect(() => {
    if (showExportModal && activePoint?.id) {
      let activeId = activePoint?.id;
      let filters = checkDataList.filter(item => !item.disabled && item.id === activeId);
      setCheckedList(filters.map(item => item.id));
    }
  }, [activePoint, showExportModal]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? checkDataList.filter(item => !item.disabled).map(item => item.id) : []);
  };
  const checkAll = checkDataList.filter(item => !item.disabled).length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < checkDataList.filter(item => !item.disabled).length;
  const exportBtnIsDisabled = useMemo(() => {
    return !(deviceId && devicePoints.length > 0 && rangePickerValue);
  }, [deviceId, devicePoints, rangePickerValue]);
  return (
    <div className={styles.page}>
      <div className={styles.breadcrumb}>
        <Breadcrumb routes={routes} />
      </div>

      <Layout style={{ height: 'initial' }} className={styles.container}>
        <Sider className={styles.sider} width={250} theme="light">
          <DeviceTree
            key={`${tenantMcid}-${companyId}`}
            selectDevice={key => {
              if (key === 'undefined') return;
              urlSearchParams.set('deviceId', key);
              setDeviceId(Number(key));
              navigate(`?${urlSearchParams.toString()}`);
            }}
            tenantMcid={tenantMcid}
            companyId={companyId}
          />
        </Sider>
        <Content className={styles.content}>
          <div className={styles.filter} style={{ alignItems: 'center' }}>
            <Radio.Group
              className={styles.pointTypeRadio}
              buttonStyle="solid"
              value={pointType}
              onChange={e => {
                setPointType(e.target.value);
                setRangePickerValue(getTimeDistance(OriginalType.ORIGINAL));
              }}
            >
              <Radio.Button value={PointTypeEnum.MODEL_POINT}>查看建模点数据</Radio.Button>
              <Radio.Button value={PointTypeEnum.NONE_MODEL_POINT}>查看非建模点数据</Radio.Button>
            </Radio.Group>

            <div className={styles.formItem}>
              <span className={styles.label}>所属租户</span>
              <Select
                style={{ width: 180 }}
                placeholder="请选择"
                value={tenantMcid}
                onChange={v => {
                  setTenantMcid(v);
                  urlSearchParams.set('tenantMcid', v);
                  setDeviceId(undefined);
                  navigate(`?${urlSearchParams.toString()}`);
                }}
              >
                {tenant?.map(i => (
                  <Option key={i.mcid} value={i.mcid}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <HistoryTab
            activeKey={activePoint ? `${activePoint.id}` : ''}
            onTabChange={key => {
              const point = devicePoints?.find(item => `${item.id}` === key);
              const pointIndex = (devicePoints ?? []).findIndex(item => `${item.id}` === key);
              setActivePointIndex(pointIndex !== -1 ? pointIndex : 0);
              onPointChange(point);
            }}
            tabData={(devicePoints || []).map(item => ({ key: `${item.id}`, name: item.name }))}
            extral={
              <Button
                type="primary"
                disabled={exportBtnIsDisabled}
                onClick={() => setShowExportModal(true)}
                icon={<UploadOutlined />}
              >
                导出
              </Button>
            }
          />

          {activePoint ? (
            <Spin spinning={!activePoint}>
              {!!devicePoints.length && (
                <div className={styles.toolBar}>
                  <div className={styles.filter}>
                    <div className={styles.formItem}>
                      <span className={styles.label}>查询维度</span>
                      <Radio.Group
                        value={dateMode}
                        onChange={e => {
                          effectChange({
                            dateMode: e.target.value,
                            rangePickerValue: getTimeDistance(e.target.value),
                          });
                        }}
                      >
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.DAY}>
                          日
                        </Radio>
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.MONTH}>
                          月
                        </Radio>
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.YEAR}>
                          年
                        </Radio>
                        <Radio
                          value={
                            pointType === PointTypeEnum.MODEL_POINT
                              ? // 建模点
                                activePoint?.type === DataPropertyType.YC
                                ? OriginalType.ORIGINAL
                                : OriginalType.ORIGINALENUM
                              : // 非建模点
                                OriginalType.ORIGINAL
                          }
                        >
                          原始数据
                        </Radio>
                      </Radio.Group>
                    </div>
                    <DateSwitch
                      mode={dateMode}
                      rangePickerValue={rangePickerValue}
                      handleRangePickerChange={handleRangePickerChange}
                    />
                  </div>

                  <Space className={styles.toolBarRight}>
                    {(activePoint.type === DataPropertyType.YC || pointType === PointTypeEnum.NONE_MODEL_POINT) && (
                      <Button
                        onClick={() => {
                          if (pageMode === PageModeType.CHART) {
                            effectChange({
                              pageMode: PageModeType.TABLE,
                            });
                          } else if (pageMode === PageModeType.TABLE) {
                            effectChange({
                              pageMode: PageModeType.CHART,
                            });
                          }
                        }}
                      >
                        切换{pageMode === PageModeType.TABLE ? '图表' : '列表'}视图
                      </Button>
                    )}
                    <Button type="link" icon={<ReloadOutlined />} onClick={() => forceUpdate()}>
                      刷新数据
                    </Button>
                  </Space>
                </div>
              )}

              <div className={styles.main}>
                {pageMode === PageModeType.TABLE ? renderTableContent() : renderChart()}
              </div>
            </Spin>
          ) : (
            <div className={styles.emptyWrap}>
              <Empty />
            </div>
          )}
        </Content>
      </Layout>
      {showExportModal && (
        <Modal
          title="选择导出数据"
          open={true}
          onOk={() => {
            if (!checkedList.length) {
              Modal.error({
                title: '导出失败',
                content: '所选择的数据属性均为空，请重新选择！',
              });
              return;
            }
            exportBtn();
          }}
          onCancel={() => {
            setShowExportModal(false);
            setCheckedList([]);
          }}
        >
          <>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              全选
            </Checkbox>
            <Divider style={{ margin: '12px 0' }} />
            <div style={{ height: 500, overflowY: 'auto' }}>
              <CheckboxGroup value={checkedList} onChange={onChange}>
                <Row>
                  {(checkDataList || []).map(item => {
                    return (
                      <Col span={24} key={item.id}>
                        <div style={{ paddingBottom: 8 }}>
                          <Checkbox disabled={item.disabled} value={item.id}>
                            <Space size={8}>
                              <span>{item.name}</span>
                              <span style={{ fontSize: 12, color: 'red' }}>
                                {item.disabled ? '该类型数据只支持导出"原始数据"维度数据！' : ''}
                              </span>
                            </Space>
                          </Checkbox>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </CheckboxGroup>
            </div>
          </>
        </Modal>
      )}
    </div>
  );
};

export default History;
