import { Checkbox, Col, Row } from 'antd';
import { DriveType } from '../../../types';
import { useContext, useMemo } from 'react';
import { BatchEdgeContent } from '../contentTypes';
import useMergedState from '../../../hooks/useMergedState';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PointCheckboxGroupProps {
  value?: Array<any>;
  onChange?: (value: Array<any>) => void;
  disabled?: boolean;
}

const getOptions = (driveType: DriveType) => {
  let options = [
    { label: '乘积系数k', value: 'k' },
    { label: '偏移系数b', value: 'b' },
    { label: '其他参数', value: 'other' },
  ];
  if (driveType === DriveType.MODBUS_TCP || driveType === DriveType.MODBUS_RTU) {
    options.unshift({ label: '开始地址', value: 'starting' });
  } else if (driveType === DriveType.DLT645_1997 || driveType === DriveType.DLT645_2007) {
    options.unshift({ label: '数据标识', value: 'dataId' });
  } else if (driveType === DriveType.IEC104) {
    options.unshift(
      { label: '公共地址', value: 'commonAddress' },
      { label: '信息体地址', value: 'informationAddress' }
    );
  } else if (driveType === DriveType.S7) {
    options.unshift({ label: '地址', value: 'address' });
  }
  return options;
};

const PointCheckboxGroup: React.FC<PointCheckboxGroupProps> = props => {
  const { baseForm } = useContext(BatchEdgeContent);
  const { driveType } = baseForm?.getFieldsValue();
  const options = useMemo(() => getOptions(driveType), [driveType]);
  const [mergedValue, setMergedValue] = useMergedState([], { ...props });
  const { disabled } = props;

  return (
    <Row wrap={false}>
      <Col flex={'none'} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox.Group
          value={mergedValue}
          options={options}
          onChange={v => {
            if (driveType === DriveType.S7 && v.includes('address')) {
              setMergedValue(Array.from(new Set([...v, 'other'])));
            } else {
              setMergedValue(v);
            }
          }}
          disabled={disabled}
        />
      </Col>
      <Col flex={1}>
        <div style={{ color: 'var(--text-color-secondary)', wordWrap: 'break-word' }}>
          <InfoCircleOutlined style={{ marginRight: 5 }} />
          {driveType === DriveType.S7
            ? 'S7协议修改地址时，也要修改其他参数中的长度字段，否则会影响S7协议地址与长度参数的校验'
            : '若只选择了其他参数，则不会生成excel模版，该内容直接在本页面完成批量操作。'}
        </div>
      </Col>
    </Row>
  );
};

export default PointCheckboxGroup;
